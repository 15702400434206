$( document ).ready(function() {
  console.log('ajax ready!');
  $('select[data-uses-select2-ajax]').each(function () {
    var $node = $(this);

    var width = $node.attr('width') || 'auto';

    $node.select2({
      width: width,
      theme: 'bootstrap',
      ajax: {
        url: $node.data('ajax-url'),
        method: 'GET',
        dataType: 'json',
        delay: 500
      },
      placeholder: 'Search for an SDID code',
      minimumInputLength: 1
    });
  });
});
$( document ).ready(function() {
  $('#shiftShowModal').on('show.bs.modal', function (event) {
    var $tr = $(event.relatedTarget); // Element that triggered the modal
    var recipient = $tr.data('shift-id'); // Extract info from data-* attributes
      $.ajax({
        type: "GET",
        dataType: 'json',
        url: "/staff/shifts/" + recipient,
        success: function(data, textStatus, xhr) {
         $($('#shiftShowModal').find('.modal-content')).html(data['html']);
        }
      });
  });
});
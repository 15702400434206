$(document).on("turbolinks:before-cache", function() {
  $('.select2-hidden-accessible').select2('destroy');
  $('.select2-hidden-accessible').off('select2:select');
});

// $(document).on('turbolinks:load', function() {
//   console.log('potato');
//   $('select[data-uses-select2]').each(function () {
//     var $node = $(this);
//
//     var placeholder = $node.attr('placeholder') || null;
//
//     $node.select2({
//       width: 'resolve',
//       placeholder: placeholder
//     });
//
//   });

// TODO install Turbolinks to change listener to Turbolinks load

$( document ).ready(function() {
  console.log("ready!");
  $('select[data-uses-select2]').each(function () {
    var $node = $(this);

    var placeholder = $node.attr('placeholder') || null;
    var width = $node.attr('width') || 'auto';

    $node.select2({
      width: width,
      placeholder: placeholder,
      theme: 'bootstrap'
    });
  });
});